import React from 'react'
import Aboutimage from './Aboutimage'

const About = () => (
  <>
    <h1>About Troop 622</h1>
    <p><Aboutimage /></p>
   <p> Our troop is often camping, hiking or canoeing in the Cascades and Olympics. The Troop goes back at least to the 1970s and probably earlier than that. Our most famous Alum is none other than Rick Steves, the PBS Travel Guru – at least that is the legend.
</p>
<h1>Meetings</h1>
<p>Our troop meetings most often are at the Rental Hall in Bothell, on Bothell Everett Highway, and occasionally at other community locations.
During the school year, our meetings generally are on Sunday evening beginning promptly at 6:30pm and ending around 8pm. During the summer months the meetings are on Mondays.
Each month, we will hold 2 meetings for the whole troop to meet and work together, while reserving other meeting dates for patrol meetings where the individual patrols can meet for planning, advancement, merit badge pursuits or perhaps just for fun! In addition, the PLC (Patrol Leadership Council) reserves a meeting date to coordinate and plan the troops’ activities.</p>
    <h1>Our Eagle Scouts</h1>
    <ul>
<li>Brandon M Lane
03/03/2019</li>
<li>Thomas J Nelson
12/02/2018</li>
<li>
Andrew T Briand
06/27/2018
</li>
<li>
Timothy R Herron
06/08/2018
</li>
<li>
Jordan W Driscoll
04/30/2018
</li>
<li>
Patrick S Connor
03/08/2017
</li>

<li>Paul McIntosh 2012</li>
<li>Michael McIntosh 2012</li>
<li>Dan Miller 2012</li>
<li>John Miller 2012</li>

<li>Paul Elbert 2011</li>
<li>Cameron Clark 2011</li>
<li>Murphy Bush (Troop 610) 2011</li>

<li>Derek Hayes 2010</li>
<li>Evan Griffith 2009</li>

<li>Riley Donohue 2007</li>

<li>Samuel Stripes 2005</li>
<li>Matthew Lease 2003</li>
<li>Caleb Stripes 2002</li>

<li>Andrew Bayne 2001</li>
<li>Ryan Burgett 2001</li>
<li>Daniel Ferguson 2001</li>

<li>Edward Eugenio 2000</li>

<li>Adam Desautels 1999</li>

<li>Chad Memmel 1993</li>
<li>James Ricketts 1991</li>


<li>William Lane
1988</li>

<li>Scott Sterling
1998</li>

 </ul>


 

 

 

 

 





  </>
)

export default About
