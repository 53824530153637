import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import config from '../../data/SiteConfig'

export default function Aboutimage() {
  return (
  <StaticQuery
  query = {graphql`
   query {
   image1: file(relativePath: { eq: "gallery/slide1.jpg" }) {
     childImageSharp {
       fixed (width: 450 height: 300) {
         ...GatsbyImageSharpFixed
         }
         }
         }
         image2: file(relativePath: { eq: "gallery/IMG_1826.jpg" }) {
          childImageSharp {
            fixed (width: 450 height: 300) {
              ...GatsbyImageSharpFixed
              }
              }
              }
         }
        `}
  render={data => (
    <span>
     <Img fixed={data.image1.childImageSharp.fixed} />
     <Img fixed={data.image2.childImageSharp.fixed} />
    </span>
  )}
  />
  )
  }
  
